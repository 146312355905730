//====================================================
//  Function: LightBox
//====================================================
function componentLightBox() {
  // if ($('.js-lightbox').length) {
  //   lightbox.option({
  //     imageFadeDuration: 350,
  //     fadeDuration: 350,
  //     resizeDuration: 400,
  //     wrapAround: false,
  //   })
  // }

  if ($('.js-gallery').length) {
    gallery = $('.js-gallery')

    gallery.each(function () {
      var inst = $(this)
      inst.lightGallery({
        selector: '.js-gallery-item',
        hash: false,
        thumbnail: false,
      })
    })
  }
}
