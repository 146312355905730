//====================================================
//  Function: Link animation
//====================================================
function componentLinkAnimation() {
  if ($('.js-link-animation').length) {
    const mainNavLinks = gsap.utils.toArray('.js-link-animation')

    mainNavLinks.forEach((link) => {
      link.addEventListener('mouseleave', (e) => {
        link.classList.add('animate-out')
      })

      link.ontransitionend = function () {
        link.classList.remove('animate-out')
      }
    })
  }
}
