//====================================================
//  Function: Accordion
//====================================================
function componentHeaderTilt() {
  if ($('.js-about-section').length) {
    document
      .querySelector('.js-about-section')
      .addEventListener('mousemove', moveImages)
  }
}

function moveImages(e) {
  aboutSection = document.querySelector('.js-about-section')

  const { offsetX, offsetY, target } = e
  const { clientWidth, clientHeight } = target

  let xPos = offsetX / clientWidth - 0.5
  let yPos = offsetY / clientHeight - 0.5

  const leftImages = gsap.utils.toArray('.hg__left .hg__image')
  const rightImages = gsap.utils.toArray('.hg__right .hg__image')

  const firstImages = gsap.utils.toArray('.hg__image .hg__image--first')
  const secondImages = gsap.utils.toArray('.hg__image .hg__image--second')

  const modifier = (index) => index * 2 + 0.5

  if (!e.target.classList.contains('about__btn')) {
    if (xPos >= 0) {
      gsap.to(aboutSection, {
        backgroundColor: '#aaa092',
        ease: 'power3.out',
      })
      gsap.to(firstImages, {
        opacity: 0.8,
      })
      gsap.to(secondImages, {
        opacity: 0,
      })
    } else {
      gsap.to(aboutSection, {
        backgroundColor: '#948775',
        ease: 'power3.out',
      })
      gsap.to(firstImages, {
        opacity: 0,
      })
      gsap.to(secondImages, {
        opacity: 0.8,
      })
    }
  }

  if (e.target.classList.contains('about__btn')) {
    xPos = 0.5
    yPos = 0.5

    leftImages.forEach((image, index) => {
      gsap.to(image, {
        duration: 1.2,
        x: xPos * 20 * modifier(index),
        y: -yPos * 30 * modifier(index),
        rotationY: xPos * 40,
        rotationX: yPos * 10,
        ease: 'power3.out',
      })
    })

    rightImages.forEach((image, index) => {
      gsap.to(image, {
        duration: 1.2,
        x: -xPos * 20 * modifier(index),
        y: yPos * 30 * modifier(index),
        rotationY: -xPos * 40,
        rotationX: yPos * 20,
        ease: 'power3.out',
      })
    })
  } else {
    leftImages.forEach((image, index) => {
      gsap.to(image, {
        duration: 1.2,
        x: xPos * 20 * modifier(index),
        y: -yPos * 30 * modifier(index),
        rotationY: xPos * 40,
        rotationX: yPos * 10,
        ease: 'power3.out',
      })
    })

    rightImages.forEach((image, index) => {
      gsap.to(image, {
        duration: 1.2,
        x: xPos * 20 * modifier(index),
        y: yPos * 30 * modifier(index),
        rotationY: xPos * 40,
        rotationX: yPos * 10,
        ease: 'power3.out',
      })
    })
  }
}
