//====================================================
//  Function: Smooth Scroolbar
//====================================================
function componentSmoothScrollbar() {
  if ($('#viewport').length) {
    bodyScrollBar = Scrollbar.init(document.querySelector('#viewport'), {
      damping: 0.07,
      alwaysShowTracks: true,
    })

    bodyScrollBar.track.xAxis.element.remove()

    // keep ScrollTrigger in sync with Smooth Scrollbar
    ScrollTrigger.scrollerProxy(document.body, {
      scrollTop(value) {
        if (arguments.length) {
          bodyScrollBar.scrollTop = value // setter
        }
        return bodyScrollBar.scrollTop // getter
      },
    })

    // when the smooth scroller updates, tell ScrollTrigger to update() too:
    bodyScrollBar.addListener(ScrollTrigger.update)
  }
}
