function pageTransitionIn({ container }) {
  const tl = gsap.timeline({
    defaults: {
      duration: 0.8,
      ease: 'power1.inOut',
    },
    onComplete: () =>
      bodyScrollBar.scrollIntoView(select('#main'), {
        damping: 1,
      }),
  })
  tl.set(loaderInner, { autoAlpha: 0 })
    .fromTo(loader, { yPercent: -100 }, { yPercent: 0 })
    .fromTo(loaderMask, { yPercent: 80 }, { yPercent: 0 }, 0)
    .to(container, { y: 150 }, 0)
  return tl
}

function pageTransitionOut({ container }) {
  const tl = gsap.timeline({
    defaults: {
      duration: 0.8,
      ease: 'power1.inOut',
    },
    onComplete: () => initContent(),
  })
  tl.to(loader, { yPercent: 100 })
    .to(loaderMask, { yPercent: -80 }, 0)
    .from(container, { y: -150 }, 0)
  return tl
}

function initPageTransitions() {
  // do something before the transition starts
  barba.hooks.before(() => {
    clearScrollTrigger()
    select('html').classList.add('is-transitioning')
  })

  barba.hooks.beforeEnter(() => {
    clearScrollTrigger()
    $('.js-navigation').removeClass(classIsCollapse)
    navigationOut()
    mainNavLinks = gsap.utils.toArray('.main-nav a')
    gsap.to(mainNavLinks, {
      autoAlpha: 1,
      x: 0,
      ease: 'power4.out',
    })
  })
  // do something after the transition finishes
  barba.hooks.after(() => {
    select('html').classList.remove('is-transitioning')
  })

  // scroll to the top of the page
  barba.hooks.enter(() => {
    window.scrollTo(0, 0)
  })

  barba.init({
    transitions: [
      {
        once() {
          componentLoader()
        },
        async leave({ current }) {
          await pageTransitionIn(current)
        },
        enter({ next }) {
          setTimeout(() => {
            bodyScrollBar.scrollIntoView(select('#main'), {
              damping: 1,
            })
          }, 1)

          pageTransitionOut(next)
          componentCounter()
          componentImageParallax()
        },
      },
    ],
  })
}
