//====================================================
//  Function: Hash Scroll
//====================================================
function partHashScroll() {
  url = window.location.hash
  hash = url.substring(url.indexOf('#'))

  if (hash.length > 0) {
    bodyScrollBar.scrollIntoView(select(hash), {
      damping: 1,
    })
  }

  window.addEventListener('hashchange', function (event) {
    url = window.location.hash
    hash = url.substring(url.indexOf('#'))

    if (hash.length > 0) {
      if ($('.js-navigation').hasClass(classIsCollapse)) {
        $('.js-navigation').removeClass(classIsCollapse)
        navigationOut()
        setTimeout(() => {
          bodyScrollBar.scrollIntoView(select(hash), {
            damping: 1,
          })
        }, 1000)
      } else {
        bodyScrollBar.scrollIntoView(select(hash), {
          damping: 1,
        })
      }
    }
  })
}
