//====================================================
//  Function: Fullscreen navigation
//====================================================
function componentFullscreenNavigation() {
  if ($('.js-navigation').length) {
    mq = window.matchMedia('(min-width: 771px)')
    if (mq.matches) {
      componentImageMovement()
    }

    initMenu()

    $('.js-navigation-trigger').on('click', function (e) {
      if ($('.js-navigation').hasClass(classIsCollapse)) {
        $('.js-navigation').removeClass(classIsCollapse)
        navigationOut()
      } else {
        navigationIn()
      }
      e.preventDefault()
    })
  }
}

function initMenu() {
  const tl = gsap.timeline()

  tl.set(mainMenuLi, {
    y: -30,
    opacity: 0,
  })
    .set(mainMenuTitle, {
      y: -30,
      opacity: 0,
    })
    .set(mainMenuImage, {
      scale: 1.5,
    })
    .set(mainMenu, {
      opacity: 1,
      yPercent: -100,
    })
    .set(mainMenuContent, {
      yPercent: 100,
    })
}

function navigationIn() {
  initMenu()

  headerNavigation.addClass(classIsCollapse)

  navTimelineIn = gsap.timeline()

  navTimelineIn
    .to(
      mainMenuImage,
      1,
      {
        scale: 1,
        ease: 'power3.easeOut',
      },
      0,
    )
    .to(
      main,
      0.7,
      {
        y: 150,
        clearProps: 'all',
        ease: 'power3.easeInOut',
      },
      0,
    )
    .to(
      mainMenu,
      0.7,
      {
        yPercent: 0,
        ease: 'power3.easeInOut',
      },
      0,
    )
    .to(
      mainMenuContent,
      0.7,
      {
        yPercent: 0,
        ease: 'power3.easeInOut',
      },
      0,
    )
    .to(
      mainMenuLi,
      0.7,
      {
        y: 0,
        opacity: 0.99,
        clearProps: 'all',
        ease: 'power3.easeOut',
      },
      0.7,
      0.1,
    )
    .to(
      mainMenuTitle,
      0.7,
      {
        y: 0,
        opacity: 0.99,
        clearProps: 'all',
        ease: 'power3.easeOut',
      },
      0.7,
      0.1,
    )
}

function navigationOut() {
  headerNavigation.removeClass(classIsCollapse)

  navTimelineOut = gsap.timeline()

  navTimelineOut
    .set(
      main,
      {
        y: -150,
      },
      0,
    )
    .to(
      main,
      0.7,
      {
        y: 0,
        clearProps: 'all',
        ease: 'power3.easeInOut',
      },
      0,
    )
    .to(
      mainMenu,
      0.7,
      {
        yPercent: 100,
        ease: 'power3.easeInOut',
      },
      0,
    )
    .to(
      mainMenuContent,
      0.7,
      {
        yPercent: -100,
        ease: 'power3.easeInOut',
      },
      0,
    )
}
