//====================================================
//  Function: Scroll & resize events
//====================================================

function partScrollResize() {
  clearScrollTrigger()

  scrollContent = select('.scroll-content')

  mainNavLinks = gsap.utils.toArray('.main-nav a')
  mainNavLinksRev = gsap.utils.toArray('.main-nav a').reverse()

  mainNavLinks.forEach((link) => {
    link.addEventListener('mouseleave', (e) => {
      // add class
      link.classList.add('animate-out')
    })
    link.ontransitionend = function () {
      //remove class
      link.classList.remove('animate-out')
    }
  })

  function navAnimation(direction) {
    const scrollingDown = direction === 1
    const links = scrollingDown ? mainNavLinks : mainNavLinksRev
    return gsap.to(links, {
      duration: 0.6,
      stagger: 0.07,
      autoAlpha: () => (scrollingDown ? 0 : 1),
      x: () => (scrollingDown ? 40 : 0),
      ease: 'power4.out',
    })
  }

  ScrollTrigger.create({
    id: 'viewportScroller',
    trigger: scrollContent,
    start: 'top top-=100',
    end: 'bottom bottom-=200',
    toggleClass: {
      targets: '.js-navigation',
      className: 'is-scrolling',
    },
    onEnter: ({ direction }) => navAnimation(direction),
    onLeaveBack: ({ direction }) => navAnimation(direction),
  })

  sectionBlack = selectAll('.section-black')

  gsap.utils.toArray(sectionBlack).forEach((section, i) => {
    navigationHeight = headerNavigation.outerHeight() / 2 + firstLoad

    ScrollTrigger.create({
      id: 'navColor',
      trigger: section,
      start: 'top top+=' + navigationHeight,
      end: 'bottom top+=' + navigationHeight,
      toggleClass: {
        targets: '.js-navigation',
        className: 'is-black',
      },
    })
  })

  sectionText = selectAll('[data-section-text]')
  headerText = select('.js-header-text')
  headerText.textContent = ''

  gsap.utils.toArray(sectionText).forEach((section, i) => {
    navigationHeight = headerNavigation.outerHeight() / 2 + firstLoad

    ScrollTrigger.create({
      id: 'navText',
      trigger: section,
      start: 'top top+=' + navigationHeight,
      end: 'bottom top+=' + navigationHeight,
      toggleClass: {
        targets: '.js-navigation',
        className: 'is-section-text',
      },
      onToggle: function (self) {
        if (self.isActive) {
          headerText.textContent = section.dataset.sectionText
          headerText.style.opacity = '1'
        } else {
          headerText.textContent = ''
          headerText.style.opacity = '0'
        }
      },
    })
  })
}

function clearScrollTrigger() {
  let triggers = ScrollTrigger.getAll()
  triggers.forEach((trigger) => {
    trigger.kill()
  })
}
