//====================================================
//  Function: Image movement
//====================================================
function componentImageMovement() {
  if ($('.js-main-menu-image').length) {
    mainMenu.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth - 0.5
      let y = e.clientY / window.innerHeight - 0.5

      gsap.to(mainMenuImage, 0.6, {
        rotationY: 5 * x,
        rotationX: 5 * y,
        ease: 'power1.easeOut',
        transformOrigin: 'center',
        transformPerspective: 900,
      })
    })
  }
}
