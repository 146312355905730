//====================================================
//  Function: Counter
//====================================================
function componentCounter() {
  if ($('.js-hero-counter').length) {
    count = $('.js-hero-counter')
    zero = { val: 0 }
    gsap.to(zero, {
      duration: 2,
      val: count.data('number'),
      onUpdate: countNumber,
    })
    function countNumber() {
      var final = gsap.utils.snap(1, zero.val)
      count.text(final)
    }
  }
}
