// --- GLOBAL VARIABLES --- //
var $window = $(window)
var $document = $(document)
var $viewport
var $header = $('.header')
var bpSmall = 771
var bpMedium = 992
var bpLarge = 1200
var classIsActive = 'is-active'
var classIsDisabled = 'is-disabled'
var classIsLast = 'is-last'
var classHasError = 'has-error'
var classIsLoading = 'is-loading'
var classIsOpened = 'is-opened'
var classIsSelected = 'is-selected'
var classIsCollapse = 'is-collapse'
var classIsAnimated = 'is-animated'
var $this = null
var $containers = $('html,body')

gsap.registerPlugin(ScrollTrigger)

const select = (e) => document.querySelector(e)
const selectAll = (e) => document.querySelectorAll(e)

let bodyScrollBar

let navTimelineIn
let navTimelineOut

const loader = select('.loader')
const loaderInner = select('.loader .inner')
const progressBar = select('.loader .progress')
const loaderMask = select('.loader__mask')
const headerNavigation = $('.js-navigation')
const logo = document.querySelector('.js-header-logo-link')
const mainMenu = select('.js-main-menu')
const mainMenuContent = select('.js-main-menu-content')
const mainMenuTitle = selectAll('.main__title')
const mainMenuLi = selectAll('.main-nav__list--row li')
const mainMenuImage = select('.js-main-menu-image')
const mainMenuImageSrc = select('.js-main-menu-image .main-menu__image--src')

let mainMenuLink
let mainMenuLinkRev
let main
let headerText
let sectionText
let sectionBlack
let scrollTriggerNavColor
let scrollTriggerNavText
let headerTitle
let navigationText
let navigationHeight
let scrollContent
let gallery

let count, zero

let mq
let aboutSection
let firstLoad = 150

let url
let hash

// --- COMPONENTS, PARTS --- //
$(document).ready(function () {
  // Intro Loader
  componentIntroLoader()
})

function initContent() {
  main = select('#main')
  select('body').classList.remove('is-loading')
  mq = window.matchMedia('(min-width: 771px)')
  mq.addListener(handleWidhtChange)

  // ---  COMPONENTS --- //
  // SmoothScrollbar
  componentSmoothScrollbar()

  // HoverReveal
  handleWidhtChange(mq)

  // LinkAnimation
  componentLinkAnimation()

  // Lightbox
  componentLightBox()

  // Scroll to
  partScrollTo()

  // Scroll Resize
  partScrollResize()

  // Hash Scroll
  partHashScroll()
}
