//====================================================
//  Function: Loader
//====================================================

function componentIntroLoader() {
  gsap.set(loader, { autoAlpha: 1 })

  gsap.set(loaderInner, { scaleY: 0.005, transformOrigin: 'bottom' })

  const progressTween = gsap.to(progressBar, {
    paused: true,
    scaleX: 0,
    ease: 'none',
    transformOrigin: 'right',
  })

  let loaderImageCount = 0,
    imageCount
  const container = select('body')

  const imgLoad = imagesLoaded(container)
  imageCount = imgLoad.images.length

  updateProgress(0)

  imgLoad.on('progress', function () {
    loaderImageCount++
    updateProgress(loaderImageCount)
  })

  function updateProgress(value) {
    gsap.to(progressTween, {
      progress: value / imageCount,
      duration: 0.3,
      ease: 'power1.out',
    })
  }

  imgLoad.on('done', function (instance) {
    gsap.set(progressBar, { autoAlpha: 0, onComplete: initPageTransitions })
  })
}

function componentLoader() {
  const tlLoaderIn = gsap.timeline({
    id: 'tlLoaderIn',
    defaults: {
      duration: 1.1,
      ease: 'power2.out',
    },
    onComplete: function () {
      initContent()
      componentImageParallax()
      componentFullscreenNavigation()
    },
  })

  const image = select('.loader__image img')
  const mask = select('.loader__image--mask')
  const line1 = select('.loader__title--mask:nth-child(1) span')
  const line2 = select('.loader__title--mask:nth-child(2) span')
  const lines = selectAll('.loader__title--mask')
  const loaderContent = select('.loader__content')

  tlLoaderIn
    .set(loaderContent, { autoAlpha: 1 })
    .to(loaderInner, {
      scaleY: 1,
      transformOrigin: 'bottom',
      ease: 'power1.inOut',
    })
    .addLabel('revealImage')
    .from(mask, { yPercent: 100 }, 'revealImage-=0.6')
    .from(image, { yPercent: -80 }, 'revealImage-=0.6')
    .from([line1, line2], { yPercent: 100, stagger: 0.1 }, 'revealImage-=0.4')

  const tlLoaderOut = gsap.timeline({
    id: 'tlLoaderOut',
    defaults: {
      duration: 1.2,
      ease: 'power2.inOut',
    },
    delay: 1,
    onComplete: function () {
      componentCounter()
      firstLoad = 0
    },
  })

  tlLoaderOut
    .to(lines, { yPercent: -500, stagger: 0.2 }, 0)
    .to([loader, loaderContent], { yPercent: -100 }, 0.2)
    .from('#main', { y: 150 }, 0.2)

  const tlLoader = gsap.timeline()
  tlLoader.add(tlLoaderIn).add(tlLoaderOut)
}
