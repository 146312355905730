//====================================================
//  Function: Image Parallax
//====================================================
function componentImageParallax() {
  if ($('.js-parallax-image').length) {
    gsap.utils.toArray('.with-parallax').forEach((section) => {
      const image = section.querySelector('.js-parallax-image')
      gsap.to(image, {
        yPercent: 20,
        ease: 'none',
        scrollTrigger: {
          id: 'imageScroller',
          trigger: section,
          start: 'top bottom',
          scrub: true,
        },
      })
    })
  }
}
