//====================================================
//  Function: Hover Reveal
//====================================================

let offerItems

function componentHoverReveal() {
  if ($('.js-offer-item').length) {
    offerItems = document.querySelectorAll('.js-offer-item')
    offerItems.forEach((section) => {
      section.imageBlock = section.querySelector('.js-offer-image')
      section.imageSrc = section.querySelector(
        '.js-offer-image .offer-image__src',
      )

      section.mask = section.querySelector('.js-offer-image-mask')
      section.content = section.querySelector('.js-offer-content')
      section.textCopy = section.querySelector('.js-offer-copy')
      section.textMask = section.querySelector('.js-offer-copy-mask')
      section.btnOffer = section.querySelector('.js-offer-btn')
      section.textP = section.querySelector('.js-offer-copy .offer__perex')

      gsap.set([section.imageBlock, section.textMask], { yPercent: -101 })
      gsap.set([section.mask, section.textP], { yPercent: 100 })
      gsap.set([section.btnOffer], { autoAlpha: 0 })
      gsap.set(section.imageSrc, { scale: 1.2 })

      section.addEventListener('mouseenter', createHoverReveal)
      section.addEventListener('mouseleave', createHoverReveal)
    })
  }
}

function getTextHeight(textCopy) {
  return textCopy.clientHeight
}

function createHoverReveal(e) {
  const {
    imageBlock,
    mask,
    content,
    textCopy,
    textMask,
    btnOffer,
    textP,
    imageSrc,
  } = e.target

  let tlReveal = gsap.timeline({
    defaults: {
      duration: 1.6,
      ease: 'power4.out',
    },
  })

  if (e.type === 'mouseenter') {
    tlReveal
      .to([mask, imageBlock, textMask, textP], {
        yPercent: 0,
        onStart: () =>
          document
            .querySelector('.js-offer-section')
            .classList.add('is-hovered'),
      })
      .to(content, { y: () => -getTextHeight(textCopy) / 2 }, 0)
      .to(imageSrc, { duration: 1.1, scale: 1 }, 0)
      .addLabel('offerBtn')
      .to(btnOffer, { autoAlpha: 1 }, 'offerBtn-=0.9')
  } else if (e.type === 'mouseleave') {
    tlReveal
      .to([mask, textP], {
        yPercent: 100,
        onStart: () =>
          document
            .querySelector('.js-offer-section')
            .classList.remove('is-hovered'),
      })
      .to([imageBlock, textMask], { yPercent: -101 }, 0)
      .to(content, { y: 0 }, 0)
      .to(imageSrc, { scale: 1.2 }, 0)
      .to(btnOffer, { autoAlpha: 0 }, 0)
  }
  return tlReveal
}

function resetProps(elements) {
  if (elements.length) {
    gsap.killTweensOf('*')
    elements.forEach((el) => {
      el && gsap.set(el, { clearProps: 'all' })
    })
  }
}

function handleWidhtChange(mq) {
  if (mq.matches) {
    componentHoverReveal()
    componentHeaderTilt()
    $('.js-offer-section').addClass('init-offer')
  } else {
    if (
      $('.js-offer-item').length &&
      $('.js-offer-section.init-offer').length
    ) {
      $('.js-offer-section').removeClass('init-offer')
      clearOfferItems()
    }
  }
}

function clearOfferItems() {
  offerItems = document.querySelectorAll('.js-offer-item')
  offerItems.forEach((section) => {
    section.removeEventListener('mouseenter', createHoverReveal)
    section.removeEventListener('mouseleave', createHoverReveal)

    const {
      imageBlock,
      mask,
      content,
      textCopy,
      textMask,
      textP,
      imageSrc,
    } = section

    resetProps([imageBlock, mask, content, textCopy, textMask, textP, imageSrc])
  })
}
