//====================================================
//  Function: Scroll to
//====================================================
function partScrollTo() {
  $('.js-scroll-trigger').bind('click', function () {
    var inst = $(this)
    var target = $(this).attr('data-scroll')
    var navigationHeight = 0
    if (target.length) {
      if ($('#' + target + '').length == 0) {
        return false
      }

      if ($('.js-navigation').hasClass(classIsCollapse)) {
        $('.js-navigation').removeClass(classIsCollapse)
        navigationOut()
        setTimeout(() => {
          bodyScrollBar.scrollIntoView(select('#' + target + ''), {
            damping: 1,
          })
        }, 1000)
      } else {
        bodyScrollBar.scrollIntoView(select('#' + target + ''), {
          damping: 1,
        })
      }

      return false
    }
  })
}
